
<template>
  <!-- 公告管理 -->
  <div class="">
    <ax-table
      ref="logTable"
      :columns="columns"
      :show-search="true"
      :searchForm="searchForm"
      :isCard="true"
      :toolActions="toolActions"
      @action-column-click="actionColumnClick"
      :dataSourceApi="api.list"
      @add="openDialog"
      @pop-confirm="popConfirm"
    >
    </ax-table>
    <!-- 新增弹窗 -->
    <lssueDialog ref="lssueDialog" @refresh="refresh"></lssueDialog>
    <noticeDetails ref="noticeDetails" @refresh="refresh"></noticeDetails>
  </div>
</template>
 
 <script>
import lssueDialog from "../components/lssueDialog.vue";
import noticeDetails from "../components/noticeDetails.vue";
import api from "./api";
import { initGridFormData } from "@/common/tools";
const searchForm = [
  {
    label: "标题",
    type: "",
    model: "title",
    options: {
      placeholder: "请输入",
    },
    formItem: {},
    col: { span: 6 },
  },
];
export default {
  components: { lssueDialog, noticeDetails },
  data() {
    return {
      api,
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "标题",
            dataIndex: "title",
            ellipsis: true,
            key: 3,
            width: 120,
          },
          {
            title: "发布人",
            dataIndex: "createBy",
            ellipsis: true,
            key: 4,
            width: 80,
          },
          {
            title: "发布时间",
            dataIndex: "createTime",
            ellipsis: true,
            key: 5,
          },
        ],
        true,
        {
          maxWidth: 100,
          actions: [
            {
              props: (record) => {
                return {
                  text: "详情",
                  name: "detail",
                  type: "#2A5CFF",
                  link: true,
                  // disabled: !(record.status === "1"),
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "delete",
                  type: "#f95a5a",
                  link: true,
                  popDisabled: false, //delete是false是禁用
                  // disabled: !(record.status === "1"),
                };
              },
            },
          ],
        }
      ),
      searchForm: initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 80,
      }),
      // 操作按钮
      toolActions: [{ name: "add", text: "新增" }],
      visible: false,
    };
  },

  methods: {
    openDialog() {
      this.$refs.lssueDialog.openDialog();
    },
    refresh() {
      this.$refs.logTable.getDataSource();
    },
    // 点击删除操作的api
    async popConfirm({ record }) {
      try {
        const res = await api.del(record.id);
        if (res.status === 200) {
          this.$message.success("删除成功");
        } else {
          this.$message.error("删除失败");
        }
        // 刷新列表数据
       this.refresh()
      } catch (error) {
        console.log(error);
        
      }
    },
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        //详情
        case "detail":
          this.$refs.noticeDetails.openDialog(record);
          break;
        //删除
        case "delete":
          break;
      }
    },
  },

  created() {},

  mounted() {},
};
</script>
 <style lang='less' scoped>
</style>